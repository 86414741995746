import { camelcase } from './keymap';

export default {
  supportMail: 'e-learning@medgurus.de',
  tenantName: 'MedGurus',
  guruSessionsEnabled: false,
  cloudinary: camelcase({"cloud_name":"medgurus","preset_name":"learning-platform"}),
  bugsnagApiKey: camelcase("88c9dae4bf14128dfb76d7ccca410878"),
  mascot: 'Gus',
  mascotImage: 'gus.jpg',
  mascotAlt: 'Gus the e-learning mascot'
};
